import React, { useEffect, useState } from 'react'
import PageIntro from './intro_section/IntroSection'
import EventsSection from './events_section/EventsSection'
import Header from '../menu/Header'
import ReviewsSection from './reviews_section/ReviewsSection'
import { GeneralTexts, HistorySectionTexts, HomeIntroTexts } from "../../enums/page_texts/homepage_texts";
import { TopSectionsPictures, HomeHistorySectionPictures } from '../../enums/images'
import DescriptiveSection from '../general/DescriptiveSection'
import HistorySection from './history_section/HistorySection'
import GallerySection from './gallery_section/GallerySection'
import { fetchGalleryCards, fetchPhotosAndReviews, loadHomePageData,  } from '../../services/api'
import LoadingSpinner from '../general/LoadingSpinner'
import StaticFooter from '../footer/StaticFooter'
import Footer from '../footer/Footer'
import { GalleryCard, PhotoOrReview } from '../../interfaces/types'
import useWindowSize from '../../services/useWindowSize'

const galleryTitle = "Galerie"


const HomePage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [photosAndReviews, setPhotosAndReviews] = useState<PhotoOrReview[]>([])
  const [historyMainImage, setHistoryMainImage] = useState('')
  const [historySmallImages, setHistorySmallImages] = useState<string[]>([])
  const [galleryCards, setGalleryCards] = useState<GalleryCard[]>([])
  const { width } = useWindowSize();

  useEffect(() => {
    const loadTexts = async () => {
      
      const reviews = await fetchPhotosAndReviews()
      setPhotosAndReviews(reviews)
      const response = await fetchGalleryCards()
      setGalleryCards(response)


      await loadHomePageData()
      setHistoryMainImage(HomeHistorySectionPictures.MainPicture)
      setHistorySmallImages([
        HomeHistorySectionPictures.TopLeftPicture,
        HomeHistorySectionPictures.TopRightPicture,
        HomeHistorySectionPictures.BottomLeftPicture,
        HomeHistorySectionPictures.BottomRightPicture,
      ])
      setLoading(false)
    }

    loadTexts()
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <Header />
      {/* INTRO BANNER */}
      <PageIntro title={GeneralTexts.Name}
        description={HomeIntroTexts.DescriptiveText}
        backgroundImage={TopSectionsPictures.HomePage}
        modalText={HomeIntroTexts.ModalText}
        buttonText={HomeIntroTexts.ButtonText}
        aboutModal={HomeIntroTexts.AboutModal}
        closeModal={HomeIntroTexts.CloseModal}
        isModalList={true} />
      <EventsSection />
      {/* HISTORY */}
      <HistorySection title={HistorySectionTexts.Title} subtitle={HistorySectionTexts.Subtitle} description={HistorySectionTexts.Description} mainImage={historyMainImage} smallImages={historySmallImages} />
      {/* REVIEWS */}
      {width > 768 && (
        <DescriptiveSection title="">
          <ReviewsSection items={photosAndReviews} />
        </DescriptiveSection>
      )}
      {/* GALLERY */}
      <GallerySection title={galleryTitle} cards={galleryCards} />
      <StaticFooter />
      <Footer />
    </div>
  )
}

export default HomePage