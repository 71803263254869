import { styled } from '@mui/system';
import { FormControlLabel, Box, Typography } from '@mui/material';

export const IntroTypography = styled(Typography)(() => ({
  color: 'white',
  fontSize: '1.2rem',
  lineHeight: '1.5',
  margin: '5%',
  textAlign: 'justify'
}))

export const SectionContainer = styled('section')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: `radial-gradient(circle, ${theme.palette.secondary.light} 1px, transparent 1px)`,
  backgroundSize: '20px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const StyledBackgroundDiv = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: `radial-gradient(circle, ${theme.palette.secondary.light} 1px, transparent 1px)`,
  backgroundSize: '20px 20px',
}))



export const StyledRadioGroupContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  boxShadow: `0px 0px 10px 2px rgba(255, 255, 255, 0.5)`,
  margin: `${theme.spacing(4)} auto`,
  width: 'auto',
  maxWidth: '600px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: '1.2rem',
  },
  "& .Mui-checked": {
      color: theme.palette.info.main,
  },
}));