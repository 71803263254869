import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const SectionContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '0 10%',
    paddingTop: '2%',
    boxSizing: 'border-box',

}));

export const CircleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export const Circle = styled('div')<{ imageUrl: string }>(({ imageUrl, theme }) => ({
    width: '40vh',
    height: '40vh',
    minWidth: '200px',
    minHeight: '200px',
    borderRadius: '50%',
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    opacity: 0.75,
    padding: '10px',
    boxShadow: `0px 0px 10px 2px rgba(255, 255, 255, 0.5)`,
    transition: 'background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        transform: 'scale(1.05)',
        opacity: 1,
    },
}));

export const TextContainer = styled('div')({
    color: 'white',
    textAlign: 'center',
    marginTop: '10px',
});

export const CircleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '3rem',
    fontWeight: 700,
    marginBottom: '10px',
    textAlign: 'center',
    color: theme.palette.primary.main
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    margin: '50px',
    textAlign: 'center',
    color: theme.palette.primary.main
}));

export const CircleColumn = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

}))