import { styled } from '@mui/system';
import { Card, Box, IconButton, Typography } from '@mui/material';

export const GalleryContainer = styled(Box)(({ theme }) => ({
  gridColumn: 'span 6',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '60%',
  margin: 'auto',
  height: 300,
  [theme.breakpoints.down('sm')]: {
    height: 250,
    maxWidth: '100%',
  },
}));


export const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 200,
  maxWidth: 200,
  maxHeight: 250,
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1C1E24',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  transition: 'transform 0.3s ease',
  margin: '0 10px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 150,
    maxWidth: 150,
    maxHeight: 250,
  },
}));


export const TitleBox = styled(Box)(({ theme }) => ({
  gridColumn: 'span 2',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
  },
}));


export const GalleryScroll = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  flex: 1,
  scrollBehavior: 'smooth',
  scrollSnapType: 'x mandatory',
  height: '100%',
  alignItems: 'center',
  '&::-webkit-scrollbar': { display: 'none' },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

export const StyledArrowIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const StyledCardHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: theme.spacing(1),
}));