import { ThemeProvider } from '@emotion/react';
import MainRouter from './components/navigation/Routes';
import OrientationLock from './components/general/OrientationLock';

import theme from './styles/theme';

function App() {
    return (
        <OrientationLock>
            <div className="App">
                <ThemeProvider theme={theme}>
                    <MainRouter />
                </ThemeProvider>
            </div>
        </OrientationLock>
    );
}

export default App;