import { Box, Card, styled, Typography } from "@mui/material";
import { IconButton } from "@mui/material";

export const StyledGalleryContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    paddingTop: '2%',
    paddingBottom: '2%',
    height: '70vh',
    overflow: 'hidden',
}))

export const StyledGalleryWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
})

export const StyledLeftArrowIconButton = styled(IconButton)({
    color: 'white',
    marginLeft: '1%'
})

export const StyledRightArrowIconButton = styled(IconButton)({
    color: 'white',
    marginRight: '1%'
})

export const StyledCardsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    paddingLeft: '5%',
    paddingRight: '5%',
    scrollBehavior: 'smooth',
    width: '100%',
    overflow: 'hidden',
}))

export const StyledCard = styled(Card)(({ theme }) => ({
    flexShrink: 0,
    width: '80%',
    maxWidth: '600px',
    margin: '0 10px',
    borderRadius: 2,
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': { boxShadow: 4 },
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '300px',
    },
}));

export const StyledCardText = styled(Typography)(({ theme }) => ({
    padding: 1,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center'
}))