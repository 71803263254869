import React from 'react';
import { Column } from '../../styles/Navigation/StaticFooter.style';
import { DescriptionTypography, SectionContainer, CircleContainer, Circle, CircleTypography, TextContainer } from '../../styles/EventsPages/EventTypeDescription.style';

interface EventTypeDescriptionSectionProps {
    descriptions: {
        subtitle: string;
        text: string;
        imageUrl: string;
    }[];
}



const EventTypeDescriptionSection: React.FC<EventTypeDescriptionSectionProps> = ({ descriptions }) => {
    return (
      <SectionContainer>
        <CircleContainer>
          {descriptions.map((event, index) => (
            <Column key={index}>
              <Circle imageUrl={event.imageUrl} aria-label={event.subtitle}>
                <CircleTypography>
                  {event.subtitle}
                </CircleTypography>
              </Circle>
              <TextContainer>
                <DescriptionTypography>
                  {event.text}
                </DescriptionTypography>
              </TextContainer>
            </Column>
          ))}
        </CircleContainer>
      </SectionContainer>
    )
  }

export default EventTypeDescriptionSection;
