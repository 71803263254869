import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const HistorySectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: theme.palette.secondary.main,
    height: 'auto',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        height: '100vh',
    },
}));

export const ImageCollage = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
        width: '50%',
        marginBottom: '0',
        marginLeft: '5%',
    },
}));

export const MainImage = styled('img')(({ theme }) => ({
    width: '80%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
}));

export const SmallImage = styled('img')<{ position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' }>(
    ({ position }) => ({
        position: 'absolute',
        width: '30%',
        height: 'auto',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        ...(position === 'top-left' && { top: '-5%', left: '-5%' }),
        ...(position === 'top-right' && { top: '-2%', right: '-5%', zIndex: 2 }),
        ...(position === 'bottom-left' && { bottom: '-5%', left: '-5%', zIndex: 2 }),
        ...(position === 'bottom-right' && { bottom: '-2%', right: '5%', zIndex: 2 }),
    })
);

export const TextContent = styled(Box)(({ theme }) => ({
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
        width: '50%',
        textAlign: 'left',
        marginLeft: '5%',
    },
}));

export const BigTitle = styled(Typography)(({ theme }) => ({
    fontSize: '3rem',
    fontWeight: 700,
    color: theme.palette.info.main,
    marginBottom: '10px',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#CCCCCC',
    marginBottom: '20px',
}));

export const Paragraph = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    color: '#AAAAAA',
    lineHeight: 1.6,
    marginBottom: '20px',
}));

export const ReadMoreButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.info.main,
    color: '#fff',
    textTransform: 'none',
    padding: '10px 20px',
    '&:hover': {
        backgroundColor: '#9222b1',
    },
}));