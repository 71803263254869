import React, { useEffect, useState } from "react"
import { Box, Button, DialogTitle, DialogActions, IconButton, Modal, Step, StepLabel, Typography, DialogContent } from "@mui/material"
import { ContactFormsTexts, EventTypes, FormSteps, MaterialOptions, MusicStyles, VenueTypes } from "../../enums/page_texts/contact_forms_texts"
import Step1Form from "./Step1Form"
import Step2Form from "./Step2Form"
import Step3Form from "./Step3Form"
import Step4Form from "./Step4Form"
import Step5Form from "./Step5Form"
import Step6Form from "./Step6Form"
import CloseIcon from "@mui/icons-material/Close"
import { StyledStepper, StyledFormContainer, StyledDialog, StickyStepFormModalWrapper, StepFormButton } from "../../styles/Forms/StepsForm.style"
import { Check, CheckCircle } from "@mui/icons-material"
import { submitForm } from "../../services/api"

interface StepFormModalProps {
    isOpen?: boolean,
}

const StepFormModal: React.FC<StepFormModalProps> = ({ isOpen = false }) => {
    const [activeStep, setActiveStep] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [openValidation, setOpenValidation] = useState(false)

    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        setIsModalOpen(isOpen)
    }, [isOpen])

    const [formData, setFormData] = useState<{
        //step 1
        eventType: string;
        otherEventType?: string;
        isPrivateEvent: boolean;
        materialList: string[];
        otherMaterial?: string[];
        isMaterialInVenue?: string;
        venueType: string;
        otherVenueType?: string;
        //step 2
        musicStylesList: string[];
        otherMusicStyle?: string;
        guestsAge: string;
        budget: string;
        guestsAmount: string;
        //step 3
        venueAddress: string; //UPDATE TYPE
        eventDate: string; //UPDATE TYPE
        isDateFlexible: boolean;
        startHour: string;
        endHour: string;
        otherInformation: string;
        //step 4
        wantsArtis: boolean;
        //step 5
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        tvaNumber?: string;
    }>({

        eventType: "",
        isPrivateEvent: false,
        materialList: [],
        venueType: "",
        musicStylesList: [],
        guestsAge: "",
        budget: "",
        guestsAmount: "",
        venueAddress: "",
        eventDate: "",
        isDateFlexible: false,
        startHour: "",
        endHour: "",
        otherInformation: "",
        wantsArtis: false,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        tvaNumber: "",
    })



    const [errors, setErrors] = useState<{ [key: string]: string }>({})

    const steps = [FormSteps.Step1, FormSteps.Step2, FormSteps.Step3, FormSteps.Step4, FormSteps.Step5, FormSteps.Step6]
    const handleCloseModal = () => {
        setIsConfirmationOpen(true)
    }

    const confirmCloseModal = () => {
        setFormData({
            eventType: "",
            isPrivateEvent: false,
            materialList: [],
            venueType: "",
            musicStylesList: [],
            guestsAge: "",
            budget: "",
            guestsAmount: "",
            venueAddress: "",
            eventDate: "",
            isDateFlexible: false,
            startHour: "",
            endHour: "",
            otherInformation: "",
            wantsArtis: false,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            tvaNumber: "",
        })
        setActiveStep(1)
        setErrors({})
        setIsModalOpen(false)
        setIsConfirmationOpen(false)

        if (window.location.pathname === "/contact-form-open") {
            window.location.href = "/contact-forms";
        }
    }

    const cancelCloseModal = () => {
        setIsConfirmationOpen(false)
    }

    const handleNext = () => {
        if (activeStep === 1 && validateStep1()) {
            if (formData.eventType === EventTypes.Public2) {
                setActiveStep((prev) => prev + 2);
            } else {
                setActiveStep((prev) => prev + 1);
            }
        } else if (activeStep === 2 && validateStep2()) {
            setActiveStep((prev) => prev + 1)
        } else if (activeStep === 3 && validateStep3()) {
            setActiveStep((prev) => prev + 1)
        } else if (activeStep === 4 && validateStep4()) {
            setActiveStep((prev) => prev + 1)
        } else if (activeStep === 5 && validateStep5()) {
            setActiveStep((prev) => prev + 1)
        }
    }

    const handleBack = () => {
        setErrors({})
        if (formData.eventType === EventTypes.Public2 && activeStep === 3) {
            setActiveStep((prev) => prev - 2);
        } else {
            setActiveStep((prev) => prev - 1);
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            if (newErrors[name]) {
                delete newErrors[name];
            }
            return newErrors;
        });
    }

    const handleMaterialListChange = (material: string) => {
        setFormData((prev) => ({
            ...prev,
            materialList: prev.materialList.includes(material)
                ? prev.materialList.filter((item) => item !== material)
                : [...prev.materialList, material],
        }))
    }

    const handleMusicStylesListChange = (musicStyle: string) => {
        setFormData((prev) => ({
            ...prev,
            musicStylesList: prev.musicStylesList.includes(musicStyle)
                ? prev.musicStylesList.filter((item) => item !== musicStyle)
                : [...prev.musicStylesList, musicStyle],
        }))
    }

    const handleSubmit = async () => {
        if (validateStep1() && validateStep2() && validateStep3() && validateStep4() && validateStep5()) {
            console.log("Submitted data:", formData)

            try {
                const message = await submitForm(formData)
                console.log(message)
            } catch (error) {
                console.error('Error submitting form:', error)
            }
            
            confirmCloseModal()
            openValidationDialog()

        }
    }

    const openValidationDialog = () => {
        setOpenValidation(true);

        setTimeout(() => {
            setOpenValidation(false);
        }, 3000);
    }

    const handleCloseValidation = () => {
        setOpenValidation(false);
    }

    const validateStep1 = () => {
        const errors: { [key: string]: string } = {};

        if (!formData.eventType) errors.eventType = "Veuillez sélectionner le type d'événement."
        if (formData.eventType === EventTypes.Other && !formData.otherEventType) errors.otherEventType = "Veuillez préciser le type d'événement."
        if (formData.materialList.length === 0 && !formData.isMaterialInVenue) errors.materialList = "Veuillez sélectionner au moins une option"
        if (formData.materialList.includes(MaterialOptions.MaterialOther) && !formData.otherMaterial) errors.otherMaterial = "Veuillez préciser le matériel."
        if (formData.eventType === EventTypes.Public2 && !formData.isMaterialInVenue) errors.isMaterialInVenue = "Veuillez préciser si le matériel se trouve sur place."
        if (!formData.venueType) errors.venueType = "Veuillez sélectionner le type de salle."
        if (formData.venueType === VenueTypes.VenueOther && !formData.otherVenueType) errors.otherVenueType = "Veuillez préciser le type de salle."

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const validateStep2 = () => {
        const errors: { [key: string]: string } = {};

        if (formData.musicStylesList.length === 0) errors.musicStylesList = "Veuillez sélectionner au moins un style de musique."
        if (formData.musicStylesList.includes(MusicStyles.MusicStyleOther) && !formData.otherMusicStyle) errors.otherMusicStyle = "Veuillez préciser le style de musique."
        if (!formData.guestsAge) errors.guestsAge = "Veuillez sélectionner la tranche d'âge des invités."
        if (!formData.budget) errors.budget = "Veuillez sélectionner le budget estimé."
        if (!formData.guestsAmount || +formData.guestsAmount <= 0 || +formData.guestsAmount >= 10000) errors.guestsAmount = "Veuillez sélectionner un nombre valide d'invités."

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const validateStep3 = () => {
        const errors: { [key: string]: string } = {};

        if (!formData.venueAddress) errors.venueAddress = "Veuillez entrer l'adresse de l'événement"
        const eventDate = new Date(formData.eventDate)
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        if (!formData.eventDate || isNaN(eventDate.getTime()) || eventDate < today) errors.eventDate = "Veuillez sélectionner une date valide."
        if (!formData.isDateFlexible) errors.isDateFlexible = "Veuillez sélectionner une option."
        if (!formData.startHour) errors.startHour = "Veuillez sélectionner une date de début."
        if (!formData.endHour) errors.endHour = "Veuillez sélectionner une heure de fin valide."

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    const validateStep4 = () => {
        const errors: { [key: string]: string } = {};

        if (!formData.wantsArtis) errors.wantsArtis = "Veuillez sélectionner une option."

        setErrors(errors)
        return Object.keys(errors).length === 0
    }


    const validateStep5 = () => {
        const errors: { [key: string]: string } = {};

        if (!formData.firstName) errors.firstName = "Veuillez entrer un prénom."
        if (!formData.lastName) errors.lastName = "Veuillez entrer un nom."
        // international format
        if (!formData.phoneNumber || !/^(\+?[1-9]\d{1,14}|0\d{8,9})$/.test(formData.phoneNumber.trim())) errors.phoneNumber = "Veuillez entrer un numéro de téléphone valide."
        if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email)) errors.email = "Veuillez entrer une adresse e-mail valide."
        // EU VAT format
        if (formData.tvaNumber && !/^[A-Z]{2}[0-9A-Z]{8,12}$/.test(formData.tvaNumber)) errors.tvaNumber = "Veuillez entrer un numéro de TVA valide."

        setErrors(errors)
        return Object.keys(errors).length === 0
    }

    return (
        <StickyStepFormModalWrapper>
            <StepFormButton
                variant="contained"
                onClick={() => setIsModalOpen(true)}
                sx={{
                    visibility: isModalOpen ? 'hidden' : 'visible',
                    pointerEvents: isModalOpen ? 'none' : 'auto',
                }} >
                {ContactFormsTexts.OpenFormButton}
            </StepFormButton>
            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <StyledFormContainer
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: 24,
                        p: 4,
                        width: "70%",
                    }}
                >
                    <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                        {steps[activeStep]}
                    </Typography>
                    {!isMobile && <StyledStepper activeStep={activeStep - 1} alternativeLabel>
                        {steps.map((stepIndex) => (
                            <Step key={stepIndex}>
                                <StepLabel>{stepIndex}</StepLabel>
                            </Step>
                        ))}
                    </StyledStepper>}
                    <Box mt={2}>
                        {activeStep === 1 && (
                            <Step1Form formData={formData} handleChange={handleChange} handleMaterialListChange={handleMaterialListChange} errors={errors} />
                        )}
                        {activeStep === 2 && (
                            <Step2Form formData={formData} handleChange={handleChange} handleMusicStylesListChange={handleMusicStylesListChange} errors={errors} />
                        )}
                        {activeStep === 3 && (
                            <Step3Form formData={formData} handleChange={handleChange} errors={errors} />
                        )}
                        {activeStep === 4 && (
                            <Step4Form formData={formData} handleChange={handleChange} errors={errors} />
                        )}
                        {activeStep === 5 && (
                            <Step5Form formData={formData} handleChange={handleChange} errors={errors} />
                        )}
                        {activeStep === 6 && (
                            <Step6Form formData={formData} />
                        )}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button
                            variant="contained"
                            onClick={handleBack}
                            disabled={activeStep === 1}
                        >
                            Retour
                        </Button>
                        {activeStep < steps.length ? (
                            <Button variant="contained" onClick={handleNext}>
                                Suivant
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Valider
                            </Button>
                        )}
                    </Box>
                </StyledFormContainer>
            </Modal>

            <StyledDialog open={isConfirmationOpen} onClose={cancelCloseModal}>
                <DialogTitle>{ContactFormsTexts.CloseFormDialog}</DialogTitle>
                <DialogActions>
                    <IconButton onClick={confirmCloseModal} aria-label="Confirm">
                        <CheckCircle color="success" />
                    </IconButton>
                    <IconButton onClick={cancelCloseModal} aria-label="Cancel">
                        <CloseIcon color="error" />
                    </IconButton>
                </DialogActions>
            </StyledDialog>


            <StyledDialog open={openValidation} onClose={handleCloseValidation}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent sx={{ backgroundColor: '#0F1116' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: '10px' }}>
                        <Check color="success" />
                        <Typography sx={{ color: '#F1F1F9' }}>Votre demande de devis a bien été envoyée</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseValidation} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </StyledDialog>
        </StickyStepFormModalWrapper>
    );
}

export default StepFormModal