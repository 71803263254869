import { styled, keyframes } from '@mui/system'
import { Typography, AppBar, Button, Dialog, Box } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom';

// KEYFRAMES
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% { 
    opacity: 1; 
  }
  100% { 
    opacity: 0; 
  }
`

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const slideOut = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`

const drawLine = keyframes`
  0% {
    height: 0;
  }
  100% {
    height: 80vh;
  }
`

const undrawLine = keyframes`
  0% {
    height: 80vh;
  }
  100% {
    height: 0;
    opacity: 0;
  }
`


// STYLED COMPONENTS
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: `rgba(15, 17, 22, 0.2)`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.35)',
  zIndex: 1000,
  borderRadius: '5px'
}));

export const LogoLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: '#FFFFFF',
}));

export const LogoText = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

export const MenuButton = styled(Button)(() => ({
  marginLeft: 'auto',
  borderColor: 'white',
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: 'black',
  },
}));

export const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    height: '100vh',
    overflow: 'hidden',
  },
  zIndex: 9999
}));

export const DialogContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  minHeight: '100vh',
  padding: 0,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '2rem 1rem',
    overflowY: 'auto',
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  maxHeight: '50%',
  width: 'auto',
  marginRight: '2rem',
  opacity: 0,
  animation: `${fadeIn} 0.5s forwards`,
  animationDelay: '0.4s',
  borderRadius: '10px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',

  '&.closing': {
    animation: `${fadeOut} 0.5s forwards`
  },

  // Hide image on small screens
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const CenteredMenuContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '1rem',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    marginLeft: '10px',
  },

  '&.closing': {
    animation: `${slideOut} 1s forwards`,
  },
}));

interface StyledLinkProps extends LinkProps {
  index: number;
}

export const StyledLink = styled(Link)<StyledLinkProps>(({ theme, index }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 900,
  textTransform: 'uppercase',
  fontSize: '1.5rem',
  position: 'relative',
  padding: '8px 16px',

  animation: `${slideIn} 0.6s ease-out forwards`,
  animationDelay: `${index * 0.2}s`,
  opacity: 0,
  transform: 'translateX(-50px)',

  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#ffffff',
    opacity: 0.6,
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },

  '&:hover::after': {
    transform: 'scaleX(1)',
    backgroundColor: '#ffffff',
    opacity: 0.9,
  },

  '&.closing': {
    animation: `${slideOut} 1s forwards`,
  },

}));

export const LineContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '10%',
  top: '10%',
  width: '1px',
  backgroundColor: 'white',
  animation: `${drawLine} 1s forwards`,
  marginLeft: '10%',

  '&.closing': {
    animation: `${undrawLine} 1s forwards`
  }
}));

export const MenuText = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '20%',
  left: '10%',
  transform: 'rotate(-90deg)',
  transformOrigin: 'left top',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '1.5rem',
  opacity: 0,
  animation: `${fadeIn} 1s 0.6s forwards`,
  '&.closing': {
    animation: `${fadeOut} 1s forwards`,
  },

}));

export const FooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '1rem 2rem',
  position: 'absolute',
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.secondary.main,
  zIndex: 100,

  boxSizing: 'border-box',

  [theme.breakpoints.down('sm')]: {
    bottom: theme.spacing(5),
  },
}));

export const FooterLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '& img': {
    width: '50px',
    height: 'auto',
    borderRadius: '5px',
  },

  opacity: 0,
  animation: `${fadeIn} 1s 0.6s forwards`,
  '&.closing': {
    animation: `${fadeOut} 1s forwards`,
  },
}));

export const DividerLine = styled('div')(() => ({
  flex: 1,
  height: '1px',
  backgroundColor: 'white',
  margin: '0 1.5rem',

  opacity: 0,
  animation: `${fadeIn} 1s 0.6s forwards`,
  '&.closing': {
    animation: `${fadeOut} 1s forwards`,
  },
}));

export const SocialMediaIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',

  '& svg': {
    fontSize: '1.8rem',
    color: 'white',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  opacity: 0,
  animation: `${fadeIn} 1s 0.6s forwards`,
  '&.closing': {
    animation: `${fadeOut} 1s forwards`,
  },
}));

export const CloseButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 10,
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(5),
    right: theme.spacing(3),
  },
}));