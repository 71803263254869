import React from 'react'
import { Facebook, Instagram, YouTube } from '@mui/icons-material'
import { SocialMediaLinks } from '../../enums/page_texts/homepage_texts'
import { FooterColumn1Items, FooterColumn2Items, FooterColumn3Items, FooterColumn4Items, FooterMainTexts } from '../../enums/footer_texts'
import { MaydayLogos } from '../../enums/images'
import { Column, DividerLine, FooterColumns, FooterContainer, FooterLogo, FooterLogoContainer, FooterTypography, SocialMediaIcons } from '../../styles/Navigation/StaticFooter.style'
import { IconButton, Link } from '@mui/material'
import { Email, PhoneAndroid, Create } from '@mui/icons-material'

const StaticFooter: React.FC = () => {

  const handleRedirect = (url: string) => {
    window.open(url, '_blank');
  };

  const renderFooterItem = (item: string, link: string) => {
    const isEmail = item.startsWith("Email :")
    const isPhone = item.startsWith("Tel :")

    if (isEmail) {
      const emailText = item.replace("Email :", "").trim()
      return (
        <FooterTypography key={item}>
          <Email style={{ marginRight: 8 }} />
          {emailText}
        </FooterTypography>
      )
    }

    if (isPhone) {
      const phoneText = item.replace("Tel :", "").trim()
      return (
        <FooterTypography key={item}>
          <PhoneAndroid style={{ marginRight: 8 }} />
          {phoneText}
        </FooterTypography>
      )
    }

    return (
      <FooterTypography key={item}>
        <Create style={{ marginRight: 8 }} />
        <Link href={link}>
          {item}
        </Link>
      </FooterTypography>
    )
  }


  return (
    <FooterContainer>
      <FooterLogoContainer>
        <FooterLogo>
          <img src={MaydayLogos.MainLogo} alt="Logo" />
        </FooterLogo>
        <DividerLine />
        <SocialMediaIcons>
          <IconButton onClick={() => handleRedirect(SocialMediaLinks.YouTube)}>
            <Facebook />
          </IconButton>
          <IconButton onClick={() => handleRedirect(SocialMediaLinks.YouTube)}>
            <YouTube />
          </IconButton>
          <IconButton onClick={() => handleRedirect(SocialMediaLinks.YouTube)}>
            <Instagram />
          </IconButton>
        </SocialMediaIcons>
      </FooterLogoContainer>
      <FooterColumns>
        <Column>
          <FooterTypography variant="h6">{FooterMainTexts.Column1}</FooterTypography>
          <FooterTypography sx={{ color: 'white' }}>{FooterColumn1Items.Item1}</FooterTypography>
          <FooterTypography sx={{ color: 'white' }}>{FooterColumn1Items.Item2}</FooterTypography>
          <FooterTypography sx={{ color: 'white' }}>{FooterColumn1Items.Item3}</FooterTypography>
        </Column>
        <Column>
          <FooterTypography variant="h6">{FooterMainTexts.Column2}</FooterTypography>
          <Link href={FooterColumn2Items.Link1}>{FooterColumn2Items.Item1}</Link>
          <Link href={FooterColumn2Items.Link2}>{FooterColumn2Items.Item2}</Link>
          <Link href={FooterColumn2Items.Link3}>{FooterColumn2Items.Item3}</Link>
        </Column>
        <Column>
          <FooterTypography variant="h6">{FooterMainTexts.Column3}</FooterTypography>
          <Link href={FooterColumn3Items.Link1} target="_blank" rel="noopener noreferrer">
            {FooterColumn3Items.Item1}
          </Link>
          <Link href={FooterColumn3Items.Link2} target="_blank" rel="noopener noreferrer">
            {FooterColumn3Items.Item2}
          </Link>
          <Link href={FooterColumn3Items.Link3} target="_blank" rel="noopener noreferrer">
            {FooterColumn3Items.Item3}
          </Link>
        </Column>
        <Column>
          <FooterTypography variant="h6">{FooterMainTexts.Column4}</FooterTypography>
          {renderFooterItem(FooterColumn4Items.Item1, FooterColumn4Items.Link1)}
          {renderFooterItem(FooterColumn4Items.Item2, FooterColumn4Items.Link2)}
          {renderFooterItem(FooterColumn4Items.Item3, FooterColumn4Items.Link3)}
        </Column>
      </FooterColumns>
    </FooterContainer>
  )
}

export default StaticFooter
