import { TableContainer, TableRow, TableCell, styled } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0px 0px 10px 2px rgba(255, 255, 255, 0.5)`,
  margin: 'auto',
  width: '80%',
  marginTop: '2%',
  overflowX: 'auto',
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  padding: theme.spacing(1),
  border: `5px solid ${theme.palette.divider}`,
}));

export const StyledHeaderCell = styled(StyledTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '1.2rem',
}));

export const StyledRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.secondary.light,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.background.default,
  },
}));

export const CategoryCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.5rem',
  writingMode: 'vertical-rl',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    transform: 'rotate(180deg)',
  }
}));

export const FormulaImage = styled('img')(({ theme }) => ({
  maxWidth: '50px',
  maxHeight: '50px',
  border: `2px solid ${theme.palette.divider}`,
  cursor: 'pointer',
}));