import React, { useState } from "react"
import { IntroSection, IntroContent, BoldMainTitle, DetailsButton, DescriptiveText } from '../../../styles/Homepage/IntroSection.style'
import { Overlay, ModalContent, ModalTitle, ModalText, CloseButton, StyledModalListItem, StyledModalList } from '../../../styles/Homepage/Modal.style';

interface PageIntroProps {
  title: string;
  description: string;
  backgroundImage: string;
  modalText: string;
  isModalList?: boolean;
  buttonText: string;
  aboutModal: string;
  closeModal: string;
}

const PageIntro: React.FC<PageIntroProps> = ({ title, description, backgroundImage, modalText, buttonText, aboutModal, closeModal, isModalList = false }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderModalContent = () => {
    if (isModalList) {
      const items = modalText.split('-').map((item, index) => {
        const trimmedItem = item.trim();
        return trimmedItem ? <StyledModalListItem key={index}>{trimmedItem}</StyledModalListItem> : null;
      });
      return <StyledModalList>{items}</StyledModalList>;
    }
    return <ModalText variant="body1">{modalText}</ModalText>;
  };


  return (
    <>
      <IntroSection className="test" style={{ filter: isModalOpen ? 'blur(5px)' : 'none' }} backgroundImage={backgroundImage}>
        <IntroContent>
          <BoldMainTitle variant="h2">
            {title}
          </BoldMainTitle>
          <DescriptiveText variant="h6">
            {description}
          </DescriptiveText>
          <DetailsButton onClick={handleOpenModal}>
            {buttonText}
          </DetailsButton>
        </IntroContent>
      </IntroSection>

      {isModalOpen && (
        <Overlay onClick={handleCloseModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalTitle variant="h4">{aboutModal}</ModalTitle>
            {renderModalContent()}
            <CloseButton onClick={handleCloseModal}>{closeModal}</CloseButton>
          </ModalContent>
        </Overlay>
      )}
    </>
  );
};

export default PageIntro;