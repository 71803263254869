import { styled } from '@mui/system'

export const BackgroundImageLeft = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: 'auto',
  height: '60%',
  left: '25%',
  [theme.breakpoints.down('sm')]: {
    height: '30%',
    position: 'relative',
    left: '0%',
    marginBottom: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'translateX(-50%)',
  },
  border: '10px solid #ccc',
  boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
  zIndex: 0
}))

export const BackgroundImageRight = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: 'auto',
  height: '60%',
  [theme.breakpoints.down('sm')]: {
    height: '30%',
    position: 'relative',
    right: '0%',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'translateX(50%)',
  },
  right: '25%',
  border: '10px solid #ccc',
  boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
  zIndex: 0
}))