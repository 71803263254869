import React, { useEffect, useState } from "react";

const OrientationLock: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const checkIfMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|iphone|ipad|ipod|blackberry|windows phone/i.test(userAgent);
  };

  const handleOrientationChange = () => {
    const isLandscapeMode = window.innerWidth > window.innerHeight;
    setIsLandscape(isLandscapeMode);
  };

  useEffect(() => {
    setIsMobile(checkIfMobile());
    handleOrientationChange();
    window.addEventListener("resize", handleOrientationChange);

    return () => window.removeEventListener("resize", handleOrientationChange);
  }, []);

  if (isMobile && isLandscape) {
    return (
      <div
        style={{
          textAlign: "center",
          padding: "20px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f8f9fa",
        }}
      >
        <h2 style={{ color: "#333" }}>Veuillez utiliser votre appareil en mode portrait</h2>
      </div>
    );
  }

  return <>{children}</>;
};

export default OrientationLock;
