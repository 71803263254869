import React from 'react'
import { FormControl, RadioGroup, Radio, Typography, Box } from '@mui/material';
import { StyledFormControlLabel, StyledRadioGroupContainer } from '../../styles/common.style';
import { FormulasPageGeneralTexts } from '../../enums/page_texts/formulas_texts';

interface EventTypeSelectorProps {
  eventType: 'private' | 'public';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EventTypeSelector: React.FC<EventTypeSelectorProps> = ({ eventType, onChange }) => {
  return (
    <Box sx={{ marginTop: '2%', padding: '1rem' }}>
      <StyledRadioGroupContainer>
        <Typography
          sx={{ fontWeight: 'bold', color: 'white', fontSize: { xs: '1.8rem', sm: '2.5rem' }, marginBottom: '2%' }}
        >
          J'organise un …
        </Typography>
        <FormControl>
          <RadioGroup
            row
            value={eventType}
            onChange={onChange}
            aria-label="event type selection"
            sx={{
              justifyContent: 'center',
              gap: { xs: 1, sm: 2 },
            }}
          >
            <StyledFormControlLabel value="private" control={<Radio />} label={FormulasPageGeneralTexts.PrivateEventsName} />
            <StyledFormControlLabel value="public" control={<Radio />} label={FormulasPageGeneralTexts.PublicEventsName} />
          </RadioGroup>
        </FormControl>
      </StyledRadioGroupContainer>
    </Box>
  );
}

export default EventTypeSelector