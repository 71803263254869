import React from 'react'
import { Formula } from '../../interfaces/types'
import { StyledHeaderCell, StyledTableCell, StyledTableContainer } from '../../styles/Tables/DoubleEntryTable.style'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { PublicEventsColumnTitles } from '../../enums/page_texts/formulas_texts'

interface PublicFormulasProps {
    formulasData: Formula[]
}

const PublicEventsFormulasTable: React.FC<PublicFormulasProps> = ({ formulasData }) => {

    // const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    // const handleCheckboxChange = (index: number) => {
    //     setSelectedIndex(selectedIndex === index ? null : index);
    // };

    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledHeaderCell>{PublicEventsColumnTitles.FormulaTitle}</StyledHeaderCell>
                        <StyledHeaderCell>{PublicEventsColumnTitles.ContentTitle}</StyledHeaderCell>
                        <StyledHeaderCell>{PublicEventsColumnTitles.EventsTitle}</StyledHeaderCell>
                        {/* <StyledHeaderCell>{PublicEventsColumnTitles.SelectionTitle}</StyledHeaderCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {formulasData.map((formula, index) => (
                        <TableRow key={index}>
                            <StyledTableCell sx={{ fontWeight: 700 }}>{formula.Name}</StyledTableCell>
                            <StyledTableCell>
                                {formula.Description.map((desc, descIndex) => (
                                    <div key={descIndex}>
                                        {desc}
                                    </div>
                                ))}
                            </StyledTableCell>
                            <StyledTableCell>{formula.EventType}</StyledTableCell>
                            {/* <StyledTableCell>
                            <Checkbox
                                    checked={selectedIndex === index}
                                    onChange={() => handleCheckboxChange(index)}
                                    color="primary"
                                />
                            </StyledTableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default PublicEventsFormulasTable